import axios from "axios";
const API_KEY = process.env.REACT_APP_OPENAI_APIKEY

const assistantsBaseURL = 'https://api.openai.com/v1/assistants';
const threadsBaseURL = 'https://api.openai.com/v1/threads';
const vectorStoresBaseURL = 'https://api.openai.com/v1/vector_stores';
const filesBaseURL = 'https://api.openai.com/v1/files';

const baseHeaders = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${API_KEY}`,
  'OpenAI-Beta':'assistants=v2',
}

// const retrieveAssistant = async () => {
//   try {
//     const response = await axios({
//       baseURL: assistantsBaseURL,
//       url:"/asst_bovC4kamRgTk6REqomctgIyA",
//       headers: baseHeaders,
//     })
//     return response.data;
//   } catch (error) {
//     throw error
//   }
// }

export const getMessagesByRunId = async (threadId, runId) => {
  try {
    const response = await axios({
      baseURL: threadsBaseURL,
      url:`/${threadId}/messages`,
      headers: baseHeaders,
      params: {
        run_id: runId,
      }
    })
    return response.data;
  } catch (error) {
    throw error
  }
}

export const createTestThread = async () => {
  try {
    const response = await axios({
      method: 'Post',
      baseURL: threadsBaseURL,
      headers: baseHeaders,
      data: '',
    })
    return response.data;
  } catch (error) {
    throw error
  }
}

export const deleteThread = async (threadId) => {
  try {
    const response = await axios({
      method: 'Delete',
      baseURL: threadsBaseURL,
      url:`/${threadId}`,
      headers: baseHeaders,
    })
    return response.data;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createMessageAtOpenAI = async (threadId, userMessage) => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL:threadsBaseURL,
      url:`/${threadId}/messages`,
      headers: baseHeaders,
      data: {
        role: 'user',
        content: userMessage,
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const createRun = async (threadId, aiAssistantId) => {
  try {
    const response = await axios({
      method:'POST',
      baseURL:threadsBaseURL,
      url:`/${threadId}/runs`,
      headers: baseHeaders,
      data: {
        assistant_id:aiAssistantId,
        // stream: true,
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const retrieveRun = async (threadId, runId) => {
  try {
    const response = await axios({
      baseURL:threadsBaseURL,
      url:`/${threadId}/runs/${runId}`,
      headers: baseHeaders,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const retrieveRunWithTimeout = (threadId, runId) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      retrieveRun(threadId, runId).then(res => resolve(res)).catch(error => reject(error))
    },1000)
  })
}

export const overCreateRun = async (options, onLoading, onError, onComplete) => {
  try {
    onLoading();
    const {threadId, assistantId} = options;
    let run = await createRun(threadId, assistantId); // создание ответа ассистента.
    console.log(run.status)
    while (run.status === 'queued' || run.status === 'in_progress') {
      run = await retrieveRunWithTimeout(threadId, run.id) // запрос ответа ассистента для проверки статуса.
      console.log(run.status);
    }
    if(run.status === 'completed') { // если  ответ ассистента сформирован успешно
      onComplete(run) // запускаем колбек успешного создания ответа. (скачивание сообщения ассистента)
    }
    if(run.status === 'failed' || run.status === 'expired') {
      throw new Error(`Run is ${run.status}`)
    }
  } catch (error) {
    onError(error);
  }
}